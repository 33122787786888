<template>
  <div class="list">
    <div class="listBanner">
      <img class="img_c1" src="../assets/img/banner_img5.jpg" alt="">
    </div>
    <div class="listMain wrap">
      <el-row :gutter="30">
        <el-col :span="5">
          <div class="listSlid">
            <div class="listSlidHd">
              <!-- <h3>新闻中心</h3>
              <p>NEWS</p> -->
              <h3>{{slideHd.title}}</h3>
              <p>{{slideHd.sub}}</p>
            </div>
            <div class="listSlidList">
              <!-- <div class="listSlidListInner on">
                <a href="/list">公司要闻</a>
                <span></span>
              </div>
              <div class="listSlidListInner">
                <a href="/list?t=1">项目动态</a>
                <span></span>
              </div> -->
              <div class="listSlidListInner" :class="{on : active == index}" v-for="(item, index) in slideList" :key="index">
                <a :href="`${apiName}/list?t=${index}`">{{item.name}}</a>
                <span></span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="19">
          <div class="listWrap">
            <div class="listHd clearfloat">
              <div class="fl listHdName">{{hdName}}</div>
              <div class="fr listHdGrop"><a :href="`${apiName}`">首页</a> · <a :href="`${apiName}/list`">{{slideHd.title}}</a> · <span class="txt">{{hdName}}</span> </div>
            </div>
            <div class="listContainer">
              <ul class="listList">
                <li v-for="(item, index) in listData" :key="index">
                  <a v-if="!item.is_link || item.is_link === 0" class="clearfloat" :href="`${apiName}/detail?id=${item.id}`" target="_blank">
                    <div class="time fr">
                      <h3>{{dayjs(item.create_time).format('MM-DD')}}</h3>
                      <p>{{dayjs(item.create_time).format('YYYY')}}</p>
                    </div>
                    <div class="inner single-line">
                      {{item.title}}
                    </div>
                  </a>
                  <a class="clearfloat"  v-if="item.is_link === 1" target="_blank" :href="item.link_url">
                    <div class="time fr">
                      <h3>{{dayjs(item.create_time).format('MM-DD')}}</h3>
                      <p>{{dayjs(item.create_time).format('YYYY')}}</p>
                    </div>
                    <div class="inner single-line">
                      {{item.title}}
                    </div>
                  </a>
                </li>
              </ul>
              <div class="listPage">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :current-page="pageNum"
                  :page-size="pageSize"
                  :total="total"
                  @current-change="currentChange">
                </el-pagination>
              </div>
              
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: 'list',
  data(){
    return {
      navId: 1,
      hdTitle: '',
      hdName: '',
      pageCont: '',
      active: 0,
      slideHd: {
        title: '新闻中心',
        sub: 'NEWS',
      },
      slideList:[],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      listData: []
    }
  },
  mounted(){
    var _this = this
    _this.getSubNav()
    if(_this.$route.query.t){
      _this.active = _this.$route.query.t
    }else{
      _this.active = 0
    }
    // _this.hdTitle = _this.slideList[_this.active].title
    // _this.hdName = _this.slideList[_this.active].title
    // _this.getList()
  },
  methods: {
    currentChange(e){
      var _this = this;
      _this.pageNum = e
      _this.getList()
    },
    // 查寻二级菜单
    async getSubNav() {
      var _this = this;
      
      var { data } = await _this.$http.post(_this.apiUrl + '/group/getGrouplistByNavId', {id: _this.navId});
      _this.slideList = data.data
      if (data.data.length) {
        _this.hdTitle = _this.slideList[_this.active].name
        _this.hdName = _this.slideList[_this.active].name
        _this.getList()
      }
    },
    // 查列表
    async getList() {
      var _this = this;
      
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: _this.slideList[_this.active].id, page: _this.pageNum, pageSize: _this.pageSize});
      console.log('data :>> ', data);
      _this.listData = data.data.list
      _this.total = data.data.total
    },
  }
}
</script>